import type { FormInstance } from 'antd';
import { message } from 'antd';
import { request, SlideVerify } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { api } from './api';
import type { FindPasswordForm, Response } from './interface';

export class Store {
  @observable public countDown = 60;

  @observable public timer;

  @observable public currentStep = 0; // 0-验证账号，1-验证手机号， 2-设置新密码

  @observable public findPasswordFormRef = React.createRef<FormInstance>();

  @observable public username: string;

  @observable public slideVerify = null;

  @observable public code: string;

  @observable public newPassword: string;

  @observable public newRepeatPassword: string;

  @observable public errorInfo = ''; // 后端返回的错误信息

  @observable public isSendCode = false;

  @observable public volidMobile: string;

  @observable public volidToken: string;

  // 回到上一步
  public goBackPreviousStep = action(() => {
    this.currentStep -= 1;
    this.errorInfo = '';
  });

  // 表单验证
  public handleFindPasswordFieldsChange = action((changeFields, allFields) => {
    // 解决后端错误信息与前端表单错误重叠
    this.errorInfo = '';
    if (!changeFields.length) {
      return;
    }
    if (!changeFields[0].value) {
      this.errorInfo = '';
    }
  });

  public handleFormVerify = action((data: FindPasswordForm) => {
    const queryList = [
      () => this.checkoutUserName(data),
      () => this.handleValideCode(data),
      () => this.handleValidePassword(data),
    ];
    queryList[this.currentStep]();
  });

  // 验证账号
  public checkoutUserName = action((data: FindPasswordForm) => {
    request({ url: `${api.checkUserName}?username=${data.username}&accountType=2` }).then((res: Response) => {
      if (res.data && !res.data.mobile) {
        message.error('账号不存在');
        return;
      }
      const { token, mobile } = res.data;
      this.volidToken = token;
      this.volidMobile = mobile;
      this.currentStep = 1;
      this.errorInfo = '';
    });
  });

  // 验证图形验证码
  public showImageCode = (id: string, x: number, y: number): void => {
    if (!this.volidMobile) {
      message.error('请输入手机号');
      return;
    }
    this.slideVerify?.clear();
    this.slideVerify = new SlideVerify({
      width: 240,
      height: 120,
      x,
      y,
      parentId: id,
      sliderText: '拖动滑块完成拼图，获取手机验证码',
      onSuccess: () => {
        // 发送验证码
        request({
          data: { mobile: this.volidMobile },
          method: 'post',
          url: api.sendCode,
        }).then((res: Response) => {
          this.isSendCode = true;
          this.countDown = 60;
          this.handleCountDown();
        })
          .catch((res: Response) => {
            message.error(res.data.data);
          });
      },
    });
  };

  @action public sendCode = () => {
    this.isSendCode = true;
    this.countDown = 60;
    this.handleCountDown();
  };

  // 倒计时
  public handleCountDown = action(() => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown < 1) {
        clearInterval(this.timer);
        this.isSendCode = false;
      }
    }, 1000);
  });

  // 验证数字验证码
  public handleValideCode = (data: FindPasswordForm): void => {
    request({ url: `${api.validateCode}?code=${data.code}&mobile=${this.volidMobile}&token=${this.volidToken}&accountType=2` }).then(() => {
      this.currentStep = 2;
    })
      .catch((res: Response) => {
        this.errorInfo = res.data.data;
      });
  };

  // 验证密码
  public handleValidePassword = (data: FindPasswordForm): void => {
    request({
      method: 'post',
      url: api.newPassword,
      data: {
        password: data.newPassword,
        token: this.volidToken,
        accountType: 2,
      },
    }).then((res: Response) => {
      message.success('密码修改成功');
      setTimeout(() => {
        window.location.href = '/egenie-ts-vogue/login';
      }, 2000);
    })
      .catch((res: Response) => {
        this.errorInfo = res.data.data;
      });
  };
}

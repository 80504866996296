import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';

const gotoPage = (url) => {
  window.location.assign(url);
};

export const TopBar = () => {
  return (
    <div className={styles.topBarWrap}>
      <div
        className={styles.topBar}
      >
        <img
          className={styles.topLogo}
          onClick={() => {
            gotoPage('/egenie-ts-vogue/homePage/index');
          }}
          src={`${PUBLIC_IMAGE_URL}topLogo.png`}
        />
        <div
          className={styles.link}
          onClick={() => {
            gotoPage('/egenie-ts-vogue/login');
          }}
        >
          返回登录
          {'>>'}
        </div>
      </div>
    </div>
  );
};
